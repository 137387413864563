import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Row, Col } from "react-bootstrap"
import arrowDown from "../images/icons/arrowDown.png"
import styles from "../scss/pages/_budx.module.scss"
import Layout from "../components/Layout"
import InfoBackgroundSustentabilidad from "../components/InfoBackgroundSustentabilidad.js"
import SectionSustentabilidad from "../components/SectionSustentabilidad.js"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie"
import ReactPlayer from "react-player"

const Sustentabilidad = ({ data }) => {
  const [age, setAge] = useState(true)
  const [linkVideo, setLinkVideo] = useState("")

  useEffect(() => {
    let td = Cookies.get("_td")
    window.dataLayer.push({
      brand: "Budweiser",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Sustentabilidad",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.budweiser.com.ar/sustentabilidad",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    })
  }, [])

  useEffect(() => {
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    console.log("Cookie", cookie)
    if (cookie || local) setAge(false)
    if (cookie) setAge(false)
    loadVideo()
  }, [])

  const loadVideo = () => {
    // console.log("esto es data", data.video.code)
    // const link = data.video.code
    setLinkVideo(
      `https://www.youtube.com/embed/h-02c86Tp74?autoplay=1&loop=1&controls=0&showinfo=0&fs=0&iv_load_policy=3&modestbranding=0&rel=0`
    )
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Video",
      event_action: "Play",
      event_label: "Sustentabilidad",
      interaction: true,
    })
  }

  return !age ? (
    <Layout>
      <SEO title="Sustentabilidad" />
      {/* video */}
      <div className={styles.videoWrapper}>
        {/* <iframe
          src={linkVideo}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        /> */}
        <ReactPlayer url={linkVideo} width='100%'
            height='100%' playing={false} controls config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  disablePictureInPicture: true,
                  playsInline: true,
                },
              },
            }} />
      </div>

      {/* arrow down */}
      <Row className="d-flex justify-content-center m-0">
        <Col xs={"auto"}>
          <a
            onClick={e =>
              document
                .querySelector("#SectionSustentabilidad")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <img src={arrowDown} className="ArrowDown" />
          </a>
        </Col>
      </Row>
      {/* section 1 */}
      <div id="SectionSustentabilidad">
        <InfoBackgroundSustentabilidad
          image={data.allFile.edges[0].node.childImageSharp.fluid}
        />
      </div>

      {/* section 2 */}
      <SectionSustentabilidad
        image={data.allFile.edges[1].node.childImageSharp.fluid}
      />

    </Layout>
  ) : (
    <AgeGate path="/sustentabilidad" />
  )
}

export default Sustentabilidad

export const data = graphql`
  query sustentabilidad {
    allFile(filter: { relativeDirectory: { eq: "Sustentabilidad" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
