import React,{useState, useEffect, Fragment} from 'react';
import Img from 'gatsby-image';
import {Container, Row, Col} from 'react-bootstrap';
import styles from '../scss/components/_SectionSustentabilidad.module.scss';
import iconEnergy from '../images/icons/energiaRenovable.png';
import SlideSustentabilidad from '../components/SlideSustentabilidad';
import {IsMobileDevice} from '../utils/index';

const SectionSustentabilidad = ({image}) => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(IsMobileDevice());
    },[])

    return (
        <Fragment>
        {!isMobile ? 
            <Fragment>
                <Img fluid={image} loading="eager" className={styles.ImgBackgroundSection}/>
                <div className={styles.GradientSection}/>
                    <Container fluid className={styles.ContainerSection}>
                        <Row>
                            <Col>
                                <Row className="d-flex align-items-center justify-content-center">
                                    <Col xs={'auto'} className={styles.Hidden}>
                                        <img src={iconEnergy} className={styles.IconEnergy}/>
                                    </Col>
                                </Row>
                                <Row className={styles.RowContentText}>
                                    <Col md={5}> 
                                        <Col md={12} sm={10}><h3>¿QUÉ ES LA <br/> ENERGÍA EÓLICA?</h3></Col>
                                        <Col md={12}>
                                            <p> La energía eólica es la energía obtenida a partir del viento, es
                                                decir, la energía cinética generada por efecto de las turbinas de
                                                aire, y que es convertida en electricidad u otras formas útiles
                                                para las actividades humanas. El término eólico proviene de latín
                                                aeolicus, es decir "perteneciente o relativo a Eolo", dios de los
                                                vientos en la mitología griega. En la actualidad, la energía eólica
                                                es utilizada principalmente para producir electricidad mediante
                                                aerogeneradores conectados a las grandes redes de distribución
                                                de energía eléctrica.</p>
                                        </Col>
                                    </Col>
                                    <Col md={5} className={styles.Hidden}> 
                                        <Col md={12}><h3>¿QUÉ ES Y CÓMO FUNCIONA <br/> UN AEROGENERADOR?</h3></Col>
                                        <Col md={12}>
                                            <p>Los aerogeneradores son la evolución natural de los molinos
                                            de viento y hoy en día son aparatos de alta tecnología. La
                                            mayoría de turbinas genera electricidad desde que el viento
                                            logra una velocidad entre 3 y 4 metros por segundo, genera
                                            una potencia máxima de 15 metros por segundo y se
                                            desconecta para prevenir daños cuando hay tormentas con
                                            vientos que soplan a velocidades medias superiores a 25
                                            metros por segundo durante un intervalo temporal de 10
                                            minutos.</p></Col>
                                    </Col>
                                </Row>
                                <Row className={styles.RowContentText}>
                                    <Col md={5} className={styles.Hidden}> 
                                        <Col md={12}><h3>¿DÓNDE ESTÁN LOS PARQUES EÓLICOS DE ARGENTINA?</h3></Col>
                                        <Col md={12}>
                                            <p> Los parques eólicos de Argentina se encuentran en <br/> Buenos Aires, Chubut, La Rioja y Santiago del Estero.</p>
                                        </Col>
                                    </Col>
                                    <Col md={5} className={styles.Hidden}> 
                                        <Col md={12}><h3>¿CUÁNTA ENERGÍA <br/> PRODUCE?</h3></Col>
                                        <Col md={12}>
                                            <p>La energía del generador, de 690 voltios, pasa por un
                                            transformador para adaptarla al voltaje necesario de la red de
                                            distribución, generalmente de entre 20 y 132 kilovoltios.
                                            </p>
                                        </Col>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Fragment>
            :
            <SlideSustentabilidad/>
        }
        </Fragment>
    )

}

export default SectionSustentabilidad